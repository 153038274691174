<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active">
            <router-link :to="{ name: 'home' }">Home</router-link>
          </li>
          <li><a href="javascript:void(0);">Privacy & Cookies policy</a></li>
        </ul>
      </div>
    </div>
    <section class="section--faq ps-page--business">
      <div class="container">
        <h2 class="page__title">Privacy & Cookies policy</h2>
        <div class="privacy-content">
          <p>
            Suyeemarket (hereinafter referred to as "Suyeemarket " "us" "we" or
            "our" is committed to respecting your privacy and to complying with
            applicable data protection and privacy laws. If you do submit
            personal information to Suyeemarket.com website services for example
            to order products, services etc., you can be assured that we will
            use your personal information only to support your continuing
            relationship with Suyeemarket.
          </p>

          <p>
            No information submitted by a customer will be shared, sold, reused
            in lists, or be used for any other purpose than to complete a
            transaction or address a customer service concern.
          </p>

          <p>
            We are providing this Privacy Policy Statement to help you
            understand how we collect, use and protect your information when you
            visit Suyeemarket web site and when you generally use our products
            and services. We wish to help you make informed decisions, so please
            take a few moments to read the sections below and learn how we may
            use your personal information. You should read this notice in
            conjunction with the terms & conditions of use for the Suyeemarket
            Web site and any separate terms & conditions issued with your
            specified product/service contract.
          </p>

          <div class="heading">Personal Information Collection</div>

          <p>
            We endeavour to collect and use your personal information only with
            your knowledge and consent and typically when you order and
            subsequently use our products and services, make customer enquiries,
            register for information or other services, request product
            information, submit a job application or when you respond to
            communications from us (such as questionnaires or surveys), the type
            of personal information we may collect could include your name and
            postal address, date of birth, gender, telephone and fax numbers,
            email address, and credit / debit card information; lifestyle and
            other information collected on registration or through surveys. If
            you choose to provide us with personal information it will be used
            in support of the intended purposes stated at the time at which it
            was collected, and subject to any preferences indicated by you.
          </p>

          <div class="heading">Non-personally identifying Information</div>

          <p>
            We endeavour to collect and use your personal information only with
            your knowledge and consent and typically when you order and
            subsequently use our products and services, make customer enquiries,
            register for information or other services, request product
            information, submit a job application or when you respond to
            communications from us (such as questionnaires or surveys), the type
            of personal information we may collect could include your name and
            postal address, date of birth, gender, telephone and fax numbers,
            email address, and credit / debit card information; lifestyle and
            other information collected on registration or through surveys. If
            you choose to provide us with personal information it will be used
            in support of the intended purposes stated at the time at which it
            was collected, and subject to any preferences indicated by you.
          </p>

          <div class="heading">How will we use your information?</div>

          <p>
            We may use your information for a number of purposes which includes:
            processing your orders, delivering any services, products or
            information requested by you; responding to complaints,
            administering debt recoveries; verifying your identity when required
            (you may lose your security information for example, and we may then
            need to ask you for other 'identifiable' information to protect your
            data from unauthorized access. We may also undertake market and
            product analysis based on your use of our services and products and
            contact you with information about new developments, products,
            services and special offers by post, telephone and automated means
            such as mobile text message (SMS), Email, Fax, Pager, WAP and the
            world wide web. We may also tell you about the products and services
            of carefully selected third parties and allow you to receive
            advertising and marketing information from selected third parties
            without passing control of your personal information to the third
            party. For example we may send you information with your bill, or
            you may simply receive an advertisement on your personal web and WAP
            page according to any preferences indicated by you and/or according
            to your interests. This helps us to make you aware of products and
            services that we may have specially negotiated for our own customers
            and which may be of individual interest to you. You acknowledge that
            by providing data to us, you consent to the processing of your data
            in accordance with this Privacy Policy Statement.
          </p>
           <div class="heading">When will we disclose your information to others?</div>

          <p>
            We may disclose information about you, to (i) companies for the
            purposes and subject to the terms of this Privacy Policy Statement;
            and (ii) in the event that we undergo re-organization or are sold to
            a third party, in which case you agree that any personal information
            we hold about you may be transferred to that re-organized entity or
            third party for the purposes and subject to the terms of this
            Privacy Policy Statement.
          </p>
          <p>
            Please note that Suyeemarket does not sell or pass your personal
            information to third parties (other than as set out in the paragraph
            above) unless you have given us permission or unless it is necessary
            to deliver the products and services ordered or used by you. For
            example, we may disclose your data to a credit card company to
            validate your credit card details and obtain payment when you get
            product or service. It may also be necessary to pass your data to
            the organization from whom you have ordered any products or
            services, such as a location-based service like for example 'Tell me
            where my nearest flower shop is'. Suyeemarket may also be obliged to
            disclose your personal information to meet any legal or regulatory
            requirements or obligations in accordance with local or
            international applicable law.
          </p>

          <div class="heading">Returns</div>
          <p>
            whilst every effort is made to ensure the freshness and quality of
            your items, we appreciate that some items may not be to your
            satisfaction. For all dry and ambient goods, we ask you to contact
            the manufacturers of the product. Their contact details can be found
            on the packaging or on forums online. For all fresh and frozen
            products please contact our customer service department at:
            Suyeemarket@gmail.com
          </p>
          <div class="heading">Amendments and Cancellations</div>
          <p>
            You may cancel your order up to 12hours on the day of your delivery.
            Able to cancel the order of dry food otherwise not cancel other type
            of food.
          </p>

          <div class="heading">Substitutions</div>
          <p>
            Every effort will be made to ensure that all your orders are
            fulfilled, however the possibility could arise that your selected
            items or brands may be out of stock or discontinued. In this event,
            a suitable alternative product or brand will be issued
            automatically, unless otherwise specified. As a customer you will be
            notified of the changes prior to delivery. Delivery Charges and
            Coverage The minimum delivery charge is differ from place to place
            in the current situations.
          </p>
          <div class="heading">Cookies</div>

          <p>
            We may use cookies to record details such as a user identity and
            general registration details on your PC. This helps us recognize you
            on subsequent visits so that you don't have to re-enter your
            registration details each time you visit us and allows us to carry
            out those activities mentioned in the above section "non-personally
            identifying information". Depending upon the type of browser you are
            using, you may be able to configure your browser so that: (i) you
            are prompted to accept or reject cookies on an individual basis or
            (ii) you may be able to prevent your browser from accepting any
            cookies at all. You should refer to the supplier or manufacturer of
            your web browser for specific details about cookie security.
          </p>

          <div class="heading">
            Access to your Information- Information Security
          </div>

          <p>
            Suyeemarket recognizes that its customers are increasingly concerned
            about how companies protect personal information from misuse and
            abuse and about privacy in general. Suyeemarket is constantly
            reviewing and enhancing its technical, physical and managerial
            procedures and rules to protect your personal data from unauthorized
            access, accidental loss and / or destruction. We use industry
            standard secure sockets layer (SSL) technology, for example, to
            encrypt sensitive information such as your credit card and other
            financial information. Please be aware that communications over the
            Internet, such as emails/web mails, are not secure unless they have
            been encrypted. Your communications may be routed through a number
            of countries before being delivered - this is the nature of the
            World Wide Web/Internet. Therefore, Suyee Family Supermarket cannot
            accept responsibility for any unauthorized access or loss of
            personal information that is beyond our control.
          </p>
          <div class="heading">Order Changes / Cancellations / Postpones?</div>
          <p>The management reserves the right to decide as appropriate.</p>
        </div>
      </div>
    </section>
  </main>
</template>
<style>
  .privacy-content{
    margin: 20px 0;
  }
  .privacy-content .heading {
    color: #000000;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 3px;
}
.privacy-content ul {
    list-style: disc;
    padding-left: 20px;
}
</style>